import React from 'react';
import { Container, NavbarWrapper } from './landingnav.style';
import logo from 'common/assets/image/interior/radiance-logo.png';

import Logo from 'common/components/UIElements/Logo';

const LandingNav = () => {
  
  return (
    <NavbarWrapper className='landingNavb'>
      <Container>
        <Logo
          href="/"
          logoSrc={logo}
          title="Radiance Logo"
          className="logoWrapper"
        />
        <div style={{ display: 'none', alignItems: 'center', marginRight: '25px' }}>

        <span className="call-now"> Free Consultation <br /><i className="fa fa-phone-square white fa-fw align-middle" style={{ fontSize: '23px' }}></i> <a href="tel:+919958585649"> +91-99585-85649</a> 
        </span>

        </div>
      </Container>
    </NavbarWrapper>
  );
};

export default LandingNav;
