// src/components/ImageLightbox.js
import React from 'react';

const ImageLightbox = ({ imageUrl, onClose }) => {
  return (
    <div className="lightbox">
      <div className="lightbox-content">
        <img src={imageUrl} alt="Lightbox" />
        <button onClick={onClose}>X</button>
      </div>
    </div>
  );
};

export default ImageLightbox;