import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.css';


const HairTreatmentsWrapper = styled.div`

.contactForm {
    min-height: 100px;
    background-color: #fff;
    background-image: linear-gradient(0deg,#fff0e7,#ffffff);
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    border-radius: 30px;
    padding: 30px 15px;
}

.contactForm {
    position: absolute;
    right: 90px;
    top: 40px;
    width: 300px;
    z-index: 3;
}

video {
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
}

.contactForm .form-control {
    outline: none;
    box-shadow: none;
    border-color: #a6a8ab;
    border-radius: 8px;
    font-family: inherit;
    height: 35px;
    font-size: .875em;
    font-family: inherit;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #dedede;
}

.form-group {
    margin-bottom: 1rem;
}

.contactForm .row {
    margin: 0!important;
    display: flex;
}
.pt-3, .py-3 {
    padding-top: 1rem!important;
}.form-check {
    position: relative;
    display: block;
    // padding-left: 1.25rem;
}.contactForm .form-check-label {
    font-size: .875em;
}.form-check-label {
    margin-bottom: 0;
}.p-0 {
    padding: 0!important;
}.pr-2, .px-2 {
    padding-right: .5rem!important;
}.pl-0, .px-0 {
    padding-left: 0!important;
}.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}button#btnSubmit {
    background: #efa94d;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 30px;
    border: 0;
}.form-action {
    text-align: center;
    margin-top: 15px;
}.form-check.pt-3 label a {
    color: #efa94d;
}

.contactForm h2 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}

.contactForm .formLogo img {
    width: 130px;
    background: #000;
    padding: 10px;
    border-radius: 30px;
}

.contactForm .formLogo {
    text-align: center;
    margin-top: -60px;
}
.icon-box {
    display: flex;    gap: 10px;width: 100%;
}section.hairConsultation {
    background: #efa94d;
    padding: 25px 0;
}section.hairConsultation h2 {
    text-align: left;
}.consultationText {
    font-weight: 500;
}.icon-box figure {
    // width: 50%;
    margin-bottom: 0 !important;
}.icon-box {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between !important;
    align-items: center;
}
h2 {
    font-size: 28px;
    line-height: 32px;
}section.internationalTreatments {
    padding: 50px 0;
}section.internationalTreatments h2 {
    font-size: 36px;
    line-height: 1.2;
}.swiper {
    padding: 0 10px;
}.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;
    font-weight: 800;
    color: #efab4c;
}.cardss {
    display: flex;
    align-items: center;
    gap: 20px;    width: 47%;
}.iconSets {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 35px;
}.whyChooseSection {
    padding: 60px 0;
    background: #f7f2ef;
}.whyChooseSection h2 {
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
}.whyChooseSection h3 {
    font-weight: 500;
}

.imgAdjust {
    text-align: center;
    display: flex;
    justify-content: center;
}


.googleRview {
    border: 1px solid rgb(222, 222, 222);
    border-radius: 10px;
    padding: 25px 15px;
    background: rgb(255, 255, 255);
}.googleRview {
    text-align: center;
}.googleRview img {
    width: 32px;
    display: block;
    margin: 0px auto 15px;
}.googleRview svg {
    color: rgb(239, 171, 76);
}.googleRview p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.6;
}.reviewHeadG h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}.reviewHeadG span {
    font-size: 13px;
}.reviewHeadG img {
    border-radius: 100%;
    margin-top: 15px !important;
}.reviewFold {
    padding: 60px 0;
}.reviewFold h2 {
    margin-bottom: 40px;
}.serviceWrap h5 {
    background: #efa94d;
    padding: 5px;
    text-align: center;
    font-size: 16px;
}

.py-6 {
    padding-top: 60px;
    padding-bottom: 60px;
}
.mapSection  ul {
    padding-left: 0;
}.mapSection  .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
}.mapSection .col-md-4 li, .mapSection .col-md-4 h3 {
    margin-bottom: 10px;
}.divider {
    width: 100px;
    height: 3px;
    background: #efab4c;
    margin-top: 15px;
    margin-bottom: 15px;
}.reviewVideos h2 {
    text-align: left;
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 1.2;
}.reviewFold {
    background: #f5f5f5;
}
// .reviewVideos video {
//     max-height: 350px;
// }
.heroFold video {
    max-height: 70vh;
    padding-right: 150px;
    margin-left: -150px;
}.reviewVideos  .col-md-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.lightbox-content button {
    position: absolute;
    background: transparent;
    color: #d4ad10;
    border: 0;
    border-radius: 100%;
    font-size: 24px;
    margin-top: -25px;
}

.lightbox-content {
  background: white;
  padding: 0px;
  border-radius: 5px;
}

.lightbox-content img {
  max-width: 100%;
}.error-message {
    color: red;
    font-size: 14px;
}footer .footerwrapscol .footerLeftarea a.logo img {
    width: 170px !important;
}section.ctaWrap {
    background: #efa94d;
    padding: 35px 0;
    text-align: center;
}section.ctaWrap h3 {
    text-transform: capitalize;
    line-height: 1.4;
    font-weight: 500;
    font-size: 28px;
}.dynamicReviews h2 {
    text-align: center;
}.dynamicReviews .divider {
    margin: 25px auto;
}.btnWraps a {
    background: #efa94d;
    padding: 15px 25px;
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none;
}.btnWraps {
    text-align: center;
    margin-top: 45px;
}img.googleHeroSecImg {
    position: absolute;
    width: 230px;
    z-index: 9;
}section.ctaWrap h3 a {
    color: #000000;
    text-decoration: none;
}section.ctaWrap h3 a:hover {
    color: #000000;
    text-decoration: underline;
}


.gCard {
    padding: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    border: 1px solid #dedede;
    background: #fff;
    border-radius: 5px;
}.swiper-slide .gHead img, .swiper-slide .gFooter img {
    width: 60px;
    object-fit: cover;
    border-radius: 100%;
    height: 60px;
}.gHead {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}.gHeadContent h5 {
    font-size: 16px;
    margin-bottom: 5px;
}.gCard svg {
    color: #efab4c;
}.gCard p {
    color: rgb(68, 68, 68);
    margin-top: 10px;
    margin-bottom: 15px;
}.gFooter img {
    width: 25px !important;
    height: 25px !important;
    margin-right: 10px;
}.gFooter a {
    font-size: 15px;
    text-decoration: none;
}
.gCard p.reviewPara {
    max-height: 140px;
    overflow-y: scroll;
    height: 140px;
    font-size: 15px;
    line-height: 1.6;
}.swiper-slide .gHead img {
    width: 45px !important;
    height: 45px !important;
}.swiper-slide .gHead p {
    margin-top: 0;
}
.swiper-button-prev, .swiper-button-next {
    background-color: transparent;
    border: 0;
}.hide-desk {
    display: none;
}
.consultationText h6 {
    font-size: 18px;
}

iframe {
    border: 10px solid #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.fixedCtaBtn {
    position: fixed;
    bottom: 0;
    // background: green;
    color: #ffffff;
    width: 100%;
    z-index: 9;
    text-align: center;
    
}.fixedCtaBtn a {
    color: #ffffff;
    text-decoration: none;
}.fixedCtaBtn a:hover {
    color: #000000;
}.fixedCtaBtn svg {
    margin-right: 10px;
    color: #fff;
}h3.hide-desk.heroHeading {
    text-align: center;
    font-size: 24px;
    font-style: italic;
    line-height: 1.4;
    margin-top: 20px;
    font-weight: 500;
}.hide-desk.heroHeading span {
    font-weight: 400;
    font-size: 20px !important;
    color: #ea7b0e;
}.footerInsideright li a {
    color: #ffffff;
    text-decoration: none;
}.footerInsideright li a:hover {
    color: #efa94d;
}.footerInsideright ul {
    padding-left: 0;
}img.googleHeroSecImg {
    margin-top: 15px;
}span.styledf {
    color: red;
    font-weight: 700;
}

h1.showinmobile {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;    line-height: 1.3;
}

@media screen and (min-width: 767px) {

    h1.showinmobile {
     display:none;
    }

    .fixedCtaBtn.hide-desk {
        display: inline-flex;
        flex-flow: row;
        justify-content: flex-end;
    }.fixedCtaBtn.hide-desk a span {
        display: none;
    }.fixedCtaBtn.hide-desk a {
        background: #e4994a;
        padding: 10px;
        border-radius: 100%;
        text-align: center;
        width: 50px;
        height: 50px;
        margin: 5px;
    }.fixedCtaBtn.hide-desk svg {
        fill: #ffffff;
        margin-right: 0;
    }.fixedCtaBtn.hide-desk a.whatsappBtn svg {
        font-size: 24px;
    }.fixedCtaBtn.hide-desk {
        right: 30px;
        bottom: 30px;
    }.fixedCtaBtn a.whatsappBtn {
        background: green !important;
    }
}

@media screen and (max-width: 767px) {

    img.googleHeroSecImg {
        width: 150px;
    }

    .contactForm {
        min-height: 100px;
        background-color: #fff;
        background-image: none;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
    }

    .heroFold video {
        margin-top: 50px;
    }

    .fixedCtaBtn.hide-desk {
        box-shadow: -2px -2px 8px #888888 !important;
    }

    .fixedCtaBtn.hide-desk {
        display: flex;
        flex-flow: row;
        align-items: center;
    }.fixedCtaBtn.hide-desk a {
        width: 100%;
    }.fixedCtaBtn.hide-desk a.callBtn {
        background: #efa94d;
        padding: 15px;
    }.fixedCtaBtn.hide-desk a.whatsappBtn {
        background: green;
        padding: 15px;
    }.fixedCtaBtn.hide-desk svg {
        fill: #ffffff !important;
        font-size: 20px;
    }.mainFootersite .footercpyr {
        margin-bottom: 30px !important;
    }

    // img.heroImga {
    //     width: 100%;
    // }

    // .heroFold .container {
    //     padding-left: 0;
    //     padding-right: 0;
    // }

    // .landingNavb {
    //     display: none;
    // }

    h3.hide-desk.heroHeading {
        display: none;
    }

    // img.googleHeroSecImg {

    //     display: none;
    // }

    // .heroFold video {
    //     display: none;
    // }

    .consultationText h6 {
        font-size: 18px;
    }

    section.hairConsultation h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    .hide-desk.divider {
        background: #000000;
        margin-bottom: 25px;
    }

    .icon-box {
        align-items: flex-start !important;
    }

    .hide-desk {
        display: block;
        color: #000;
        font-size: 14px;
        line-height: 1.6;
        font-weight: 400;
    }

    .heroFold .container {
        display: flex;
        flex-direction: column;
    }

    .heroFold .container video {
        order: -1;
    }

    img.googleHeroSecImg {
        top: 65px;
        left: 10px;
        // transform: translate(-50%, 0);
    }

    section.ctaWrap {
        padding: 20px 0;
    }
    
    .contactForm {
        position: relative;
        left: 0;
        right: 0;
        width: auto;
        top: 0px;
        margin: 25px 20px;
    }.contactForm .formLogo {
        display: none;
    }.contactForm h2 {
        margin-top: 0px;
    }.logoWrapper {
        margin-left: auto;
    }.heroFold video {
        max-height: 100vh;
        padding-right: 0px;
        margin-left: 0;
        
    }section.hairConsultation h2 {
        text-align: center;
    }.icon-box figure {
        width: auto;
    }.icon-box {
        align-items: center;
        margin-bottom: 15px;
    } section.internationalTreatments h2 {
        font-size: 24px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 25px;
    }.reviewVideos h2 {
        text-align: center;
        margin-bottom: 5px;
        font-size: 24px;
        line-height: 1.2;
    }.divider {
        margin: 15px auto;
    }.reviewVideos p {
        text-align: center;
    }.reviewVideos video {
        margin-bottom: 20px;
    }.py-6 {
        padding-top: 30px;
        padding-bottom: 30px;
    }.mapSection .col-md-4 {
        padding-left: 10px;
    }.mapSection  .divider {
        margin-left: 0;
    }.mapSection h3 {
        margin-top: 20px;
    }.cardss {
        width: 100%;
    }.footerInsideright ul {
        color: #ffffff;
        padding-left: 0;
    }.mainFootersite {
        padding-bottom: 0;
    }.mainFootersite .footercpyr {
        text-align: center;
    }.footerwrapscol, .footercpyr {
        padding-left: 10px;
        padding-right: 10px;
    }.contactiInfo p {
        color: #ffffff;
        font-size: 16px;
    }.logoWrapper img {
        width: 150px !important;
    }.icon-box {
        margin-left: 20px;
        margin-right: 20px;
    }.icon-box .consultationText {
        font-size: 18px;
    }.icon-box {
        // width: 60%;
        // flex-direction: column;
        // text-align: center;
        margin: 0 auto;    margin-bottom: 25px;
    }.icon-box figure {
        display: contents;
    }
}

`;


export { HairTreatmentsWrapper };