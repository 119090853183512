import styled from "styled-components";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .sticky-nav-active span.call-now a {
    color: #c07c34;
  }

  .sticky-nav-active span.call-now {
    color: #333333;
  }
`;

const NavbarWrapper = styled.div`

background: #000;
padding: 10px;

  .reusecore__navbar {
    position: relative;
  }
`;

export { Container, NavbarWrapper };
